<template>
  <v-sheet color="grey lighten-4">
    <v-progress-linear v-if="carregandoPerguntas" color="black" indeterminate />
    <validation-observer ref="formAssinar">
      <v-row no-gutters>
        <v-col no-gutters>
          <validation-provider v-slot="{ errors }" vid="informe_placa">
            <v-radio-group
              v-model="assinatura.informe_placa"
              row
              class="px-4"
              label="Placa confere com a imagem?"
              :readonly="assinado"
              :error-messages="errors"
            >
              <v-radio label="Sim" small :value="1" hide-details="auto" />
              <v-radio label="Não" small :value="0" hide-details="auto" />
            </v-radio-group>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row no-gutters class="px-3 pt-3">
        <v-col no-gutters>
          <validation-provider v-slot="{ errors }" vid="historico">
            <v-textarea
              v-model="assinatura.historico"
              label="Histórico"
              outlined
              dense
              hide-details="auto"
              :readonly="assinado"
              :rows="3"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row v-for="pergunta in alertasPerguntas" :key="pergunta.id" no-gutters>
        <v-col no-gutters class="px-3 pt-3">
          <validation-provider v-slot="{ errors }" vid="">
            <v-textarea
              v-model="respostas[pergunta.id]"
              :label="pergunta.texto_pergunta"
              outlined
              dense
              hide-details="auto"
              :readonly="assinado"
              :rows="2"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="text-right px-3 py-3">
          <v-btn
            class="text-right"
            color="info"
            :loading="carregandoAssinar"
            :disabled="assinado || carregandoPerguntas"
            @click="assinar"
          >
            <v-icon left v-text="'mdi-check'" />
            Assinar
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>
  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";
import alertasApi from "@/api/alertas";
import alertasPerguntasApi from "@/api/alertas-perguntas";

export default {
  props: {
    value: {
        type: Object,
        default: ()=>{},
      },
    alarme: {
      type: Object,
      default: () => {},
    },
    assinado: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      carregando: false,
      carregandoPerguntas: false,
      assinatura: {
        informe_placa: 1,
        historico: "",
      },
      carregandoAssinar: false,
      alertasPerguntas: [],
      respostas: [],
    };
  },
  computed: {
    ...mapGetters({
      usuarioAdministrador: "usuario/usuarioAdministrador",
      usuarioOperador: "usuario/usuarioOperador",
      usuarioInteligencia: "usuario/usuarioInteligencia",
    }),
  },
  mostrar: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
  mounted() {
    this.buscarAlertasPerguntas();
    this.carregar();
  },

  methods: {
    carregar() {
      if (this.assinado) {
        const respostaAssinatura =
          this.usuarioInteligencia || this.usuarioAdministrador
            ? this.alarme.respostas[1]
            : this.alarme.respostas[0];

        if (respostaAssinatura) {
          this.assinatura.historico = respostaAssinatura.historico;
          this.assinatura.informe_placa = respostaAssinatura.informe_placa;

          for (var resposta of respostaAssinatura.respostas) {
            this.respostas[resposta.id] = resposta.resposta_usuario;
          }
        }
      }
    },

    async buscarAlertasPerguntas() {
      try {
        this.carregandoPerguntas = true;

        const resposta = await alertasPerguntasApi.listar({
          por_pagina: 999,
          status: 1, // Somente ativas
        });

        this.alertasPerguntas = resposta.data.alertas_perguntas;
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregandoPerguntas = false;
      }
    },

    async assinar() {
      try {
        this.$refs.formAssinar.reset();
        this.carregandoAssinar = true;

        const respostas = [];

        for (let perguntaId in this.respostas) {
          if (!this.respostas[perguntaId]) {
            continue;
          }

          respostas.push({
            pergunta_id: perguntaId,
            resposta: this.respostas[perguntaId],
          });
        }

        await alertasApi.responder(this.alarme.id, {
          ...this.assinatura,
          ...{ respostas },
        });

        this.$snackbar.mostrar({
          cor: "success",
          mensagem: "Alarme assinado com sucesso",
        });
        this.$emit("input",{mostrar:false,assumido:true,assinado:true});
        this.$emit("assinado",{mostrar:false,assumido:true,assinado:true});
      } catch (e) {
        if (e.response.status === 422) {
          this.$refs.formAssinar.setErrors(this.$erroApi.validacao(e));
          return;
        }

        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregandoAssinar = false;
      }
    },
  },
};
</script>
